import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from './SplitText'

gsap.registerPlugin(ScrollTrigger, SplitText);

(function () {
    'use strict';

    var linkEmail = document.querySelector('.link-email');
    linkEmail.innerHTML = 'info@bloemenschuurgeschikt.nl';
    linkEmail.setAttribute('href', 'mailto:info@bloemenschuurgeschikt.nl');

    var linkEmailContact = document.querySelector('.footer-contact .link-email');
    linkEmailContact.innerHTML = 'info@bloemenschuurgeschikt.nl';
    linkEmailContact.setAttribute('href', 'mailto:info@bloemenschuurgeschikt.nl');

    const navigation = document.querySelector('.site-navigation');
    const hamburgerButton = document.querySelector('.site-navigation-button');

    setTimeout(() => {
        navigation.toggleAttribute('is-loading', false);
    }, 300);

    document.onscroll = () => {
        navigation.setAttribute('is-scrolling', window.scrollY > 0);
    };

    hamburgerButton.onpointerdown = () => {
        const opened = hamburgerButton.getAttribute('aria-expanded') === 'true';
        hamburgerButton.setAttribute('aria-expanded', !opened);
        navigation.setAttribute('aria-expanded', !opened);
        document.body.setAttribute('menu-expanded', !opened);
    };

    document.querySelectorAll("li.menu-item-has-children").forEach(item => {
        item.addEventListener("click", function() {
            this.toggleAttribute("is-open");
        });
    });
})();

// Add target blank to facebook posts
var linkList = document.querySelectorAll('.c-image figure a');

for(var link of linkList){
    link.target = '_blank';
    link.rel = 'noreferrer noopener';
}

// Add a fallback for facebook feed if no link is specified
document.querySelectorAll('.c-image figure').forEach(figure => {
    if (figure.querySelector('img') && !figure.querySelector('a')) {
        let aTag = document.createElement('a');
        aTag.href = 'https://www.facebook.com/profile.php?id=100089420666039';
        aTag.target = '_blank';
        aTag.rel = 'norefferer noopener';

        let img = figure.querySelector('img');

        figure.insertBefore(aTag, img);

        aTag.appendChild(img);
    }
});


// Remove href from a tag in gallery
// var elements = document.querySelectorAll('.wmis_image_link');

// elements.forEach(function(element) {
//   element.removeAttribute('href');
// });

// Function to remove href attribute from all elements with class 'wmis_image_link'
function removeHref() {
    var elements = document.querySelectorAll('.wmis_image_link');
    elements.forEach(function(element) {
        element.removeAttribute('href');
    });
}

// Initial removal of href attributes
removeHref();

// Observe changes in the DOM
var observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
        if (mutation.addedNodes.length) {
            removeHref();
        }
    });
});

// Configuration of the observer
var config = { childList: true, subtree: true };

// Start observing the target node for configured mutations
observer.observe(document.body, config);


// GSAP animations homepage
const siteNavigation = document.querySelector(".site-navigation");
const pageTitle = document.querySelectorAll(".c-hero > .wp-block-heading");
const pageIntro = document.querySelectorAll(".c-hero > .wp-block-columns");
const pageUsp = document.querySelectorAll(".c-usp-card");
const pageAbout = document.querySelector(".c-about");
const pageCurrent = document.querySelector(".c-current-theme");
const pageFacebookTitle = document.querySelector(".c-facebook-feed > .wp-block-heading");
const pageFacebookImage = document.querySelectorAll(".c-facebook-feed > .c-image > .wp-block-image");
const pageFaq = document.querySelector(".c-faq");

// Agenda
const pageCalenderTitle = document.querySelector(".calender-container > h1");
const pageCalenderCard = document.querySelectorAll(".calender-container > .calender-grid > .calender-card");
const pageAgendaVoorbeeld = document.querySelectorAll(".voorbeeld-container");

// Galerij
const pageGalerijTitle = document.querySelector(".image-grid > h1");
const pageGalerijImage = document.querySelectorAll(".image-grid > .wmis_main_container > .wmis_articles");

// Contact
const pageContactImage = document.querySelector(".contact-page > img");
const pageContactText = document.querySelectorAll(".contact-page > .contact-form");

function textSplit(element) {
    if (!element) {
        return;
    }

    if(element.anim) {
        element.anim.progress(1).kill();
        if (element.split) {
            element.split.revert();
        }
    }

    element.split = new SplitText(element, { 
        type: "lines,words,chars",
        linesClass: "split-line"
    });

    element.anim = gsap.from(element.split.chars, {
        duration: 0.6, 
        ease: "circ.out", 
        y: 180, 
        stagger: 0.02,
        opacity: 0,
        onStart: () => {
        gsap.set(element, { opacity: 1 });
        }
    });
}

function setupSplits() {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: siteNavigation,
            start: 'top center',
        },
    });

    textSplit(pageTitle);
}

function setupNavigation() {
    gsap.fromTo(siteNavigation,
        { 
            opacity: 0 
        },
        {
            duration: 0.7,
            opacity: 1,
            ease: 'linear',
            delay: 1.1
        }
    );
  }

function setupIntro() {
    gsap.fromTo(pageIntro,
        { 
            opacity: 0,
            x: -75
        },
        {
            duration: 0.7,
            x: 0,
            opacity: 1,
            ease: 'linear'
        }
    );
}

function setupUsp() {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: pageIntro,
            start: 'bottom center',
        },
      });
      
    tl.add(pageUsp.anim)
    .fromTo(pageUsp, 
        {
            opacity: 0
        },
        {
            opacity: 1,
            duration: 1,
            ease: 'linear',
            stagger: 0.2,
            delay: 0
        }
    );
}

function setupAbout() {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: pageAbout,
            start: 'top center',
        },
    });

    tl.add(pageAbout.anim)
    .fromTo(pageAbout, 
        {
            opacity: 0,
            x: -200
        },
        {
            opacity: 1,
            duration: 0.7,
            x: 0,
            ease: 'linear',
        }
    );
}

function setupCurrent() {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: pageAbout,
            start: 'center center',
        },
    });

    tl.add(pageCurrent.anim)
    .fromTo(pageCurrent, 
        {
            opacity: 0,
            x: 200
        },
        {
            opacity: 1,
            duration: 0.7,
            x: 0,
            ease: 'linear',
            stagger: 0.5,
        }
    );
}

function setupSocialFeed() {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: pageCurrent,
            start: 'bottom center',
        },
    });

    textSplit(pageFacebookTitle);
    
    tl.add(pageFacebookTitle.anim)
      .fromTo(pageFacebookImage, 
        {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.5,
            ease: 'linear',
            stagger: 0.2,
            delay: 0
          }
      );
}

function setupFaq() {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: pageFacebookImage,
            start: 'bottom center',
        },
    });
    
    tl.add(pageFaq.anim)
      .fromTo(pageFaq, 
        {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 1.5,
            ease: 'linear',
          }
      );
}

// Agenda
function setupCalenderTitle() {
    textSplit(pageCalenderTitle);
}

function setupCalenderCard() {
    gsap.fromTo(pageCalenderCard,
        { 
            opacity: 0,
        },
        {
            opacity: 1,
            duration: 0.5,
            ease: 'linear',
            stagger: 0.2,
            delay: 0
        }
    );
}

// Agenda voorbeelden
function setupAgendaVoorbeeld() {
    gsap.fromTo(pageAgendaVoorbeeld,
        { 
            opacity: 0,
        },
        {
            opacity: 1,
            duration: 1,
            ease: 'linear',
            delay: 0
        }
    );
}

// Galerij
function setupGalerijTitle() {
    textSplit(pageGalerijTitle);
}

function setupGalerijImage() {
    gsap.fromTo(pageGalerijImage,
        { 
            opacity: 0,
        },
        {
            opacity: 1,
            duration: 0.5,
            ease: 'linear',
            stagger: 0.2,
            delay: 0
        }
    );
}

// Contact
function setupContactImage() {
    gsap.fromTo(pageContactImage,
        { 
            opacity: 0,
            x: -100
        },
        {
            opacity: 1,
            x: 0,
            duration: 0.5,
            ease: 'linear',
        }
    );
}

function setupContactText() {
    gsap.fromTo(pageContactText,
        { 
            opacity: 0,
            x: 100
        },
        {
            opacity: 1,
            x: 0,
            duration: 0.5,
            ease: 'linear',
        }
    );
}

function timeLineLandingPage() {
const tl = gsap.timeline();
    
  tl.add(setupSplits, 0);
  tl.add(setupNavigation, 0);
  tl.add(setupIntro, "+=1.8");
  tl.add(setupCalenderTitle, 0);
  tl.add(setupCalenderCard, "1.8");
  tl.add(setupAgendaVoorbeeld, "1.8");
  tl.add(setupGalerijTitle, 0);
  tl.add(setupGalerijImage, "1.8");
  tl.add(setupContactImage, "0.5");
  tl.add(setupContactText, "0.5");

  setupUsp();
  setupAbout();
  setupCurrent();
  setupSocialFeed();
  setupFaq();

  return tl;
}

timeLineLandingPage();